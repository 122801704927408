import { FC, useCallback, useEffect, useState } from 'react'
import { Grid, Typography } from '../../../../../../../UI'
import useStyles from '../../../styles'
import {
  getApprovalStep,
  getApprovalValues,
  getContactInEstimate,
  getContractType,
  getDocusignEnv,
  getEstimate,
  getEstimateItems,
  getUser,
  getUserPrimaryEmail,
} from '../../../../../../../../ducks/selectors'
import { useStepperContext } from '../../../../../../../../hooks/useStepperContext'
import { useDispatch, useSelector } from 'react-redux'

import TOSDocumentSign from '../../../components/TOSDocumentSign'
import BottomButtons from '../../../components/BottomButtons'
import { configActions, estimateActions, estimateItemsActions } from 'ducks/actions'
import {
  companyRoles,
  CONTACT_ROLE,
  history,
  isEmpty,
  round,
  USER_TYPE,
} from 'helpers/index'
import { homewardLogo } from 'assets/index'

const Step3Homeowner: FC = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { id: estimateId } = history.getParamValues()

  const { setShowWarnings, setCanGoForward } = useStepperContext()
  const user = useSelector(getUser)
  const estimateItems = useSelector(getEstimateItems(true))

  const fetchEstimateItems = useCallback(
    () => {
      dispatch(
        estimateItemsActions.fetchEstimateItems(
          estimateId,
          (_succ: boolean) => {
          }
        )
      )
    },
    [dispatch, estimateId]
  )

  useEffect(() => {
    fetchEstimateItems()
  }, [])

  const { paymentOption, isOverInstallmentThreshold } =
    useSelector(getApprovalValues())

  const estimate = useSelector(getEstimate())
  const approvalStep = useSelector(getApprovalStep)

  const userFullName = `${user.firstName} ${user.lastName}`.trim()
  const userEmail = useSelector(getUserPrimaryEmail)

  const agentName = estimate?.properties.contacts?.find((contact) =>
    companyRoles.includes(contact.role as CONTACT_ROLE)
  )?.fullName
  const contactInEstimate = useSelector(getContactInEstimate(userEmail))
  const docusignEnv = getDocusignEnv()

  const docuSigned =
    (!isEmpty(contactInEstimate?.contractSignedOn?.toString()) &&
      estimate?.properties?.contractType === getContractType()) ||
    false

  const validation = !!docuSigned

  const items = estimateItems?.map((item) => {
    return `${item?.title?.length > 55 ? `${item?.title?.slice(0, 55)}...` : item?.title
      } - Quantity: ${item?.quantity} - $${round(item?.totalPrice)}`
  })

  useEffect(() => {
    setShowWarnings(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setCanGoForward(validation)
  }, [validation])

  const submitStep = () => {
    dispatch(
      estimateActions.updateEstimateByPath(
        [
          {
            op: 'replace',
            path: '/properties/approvalStep',
            value: approvalStep + 1,
          },
        ],
        (succ) => {
          dispatch(
            configActions.setConfigValue({
              type: 'overlayLoading',
              value: false,
            })
          )
        }
      )
    )
  }

  const roleToShow = [USER_TYPE.BROKER, USER_TYPE.INSTITUTIONAL].includes(
    user.clientType
  )
    ? 'Real Estate Agent'
    : user.clientType

  return (
    <Grid className={classes.container} spacing={2}>
      {paymentOption === 'pay_at_completion' && (
        <Grid
          container
          item
          className={classes.consent}
          style={{
            borderColor: '#99DDBD',
          }}
        >
          <Typography className={classes.consentText}>
            {isOverInstallmentThreshold
              ? `A ${estimate?.properties?.depositPercentage}% deposit is required on all projects. An additional 40% will be due at the midpoint of
the project, 30% will be due when 80% of the services have been completed, and 10% will
be due at the completion of the project.`
              : ` Please note, a ${estimate?.properties?.depositPercentage}% deposit is
          required on all projects.`}
          </Typography>
        </Grid>
      )}
      <TOSDocumentSign
        onSuccess={submitStep}
        title="📋 Service Agreement"
        docuSignUrl={`${docusignEnv}&Signer_UserName=${userFullName}&Signer_Email=${encodeURIComponent(userEmail)}&Fee=${round(estimate?.properties.totalWithTax || 0,2)}&UserName=${userFullName}&UserTitle=${roleToShow}&EstimateRefId=${estimate?.properties.publicId}&AgentName=${agentName || ''}&AgentLicenseNumber=${user?.narId || '-'}&FullAddress=${estimate?.properties.fullAddress}&AddressLine1=${estimate?.properties?.address?.line_1}&AddressLine2=${estimate?.properties.address?.line_2? estimate?.properties.address?.line_2: `${estimate?.properties.address?.city}, ${estimate?.properties.address?.state} ${estimate?.properties.address?.zipCode}`}${estimate?.properties.address?.line_2? `&AddressLine3=${estimate?.properties.address?.city}, ${estimate?.properties.address?.state} ${estimate?.properties.address?.zipCode}`: ''}&Items1=${items.join(`%0A`)}`}
        docuSigned={docuSigned}
      />
      <BottomButtons
        buttonBack={{
          title: 'Back',
          action: () => {
            dispatch(
              configActions.setConfigValue({
                type: 'overlayLoading',
                value: true,
              })
            )

            dispatch(
              estimateActions.updateEstimateProperties(
                {
                  approvalStep: approvalStep - 1,
                },
                (succ) => {
                  dispatch(
                    configActions.setConfigValue({
                      type: 'overlayLoading',
                      value: false,
                    })
                  )
                }
              )
            )
          },
          // disable: true,
        }}
        buttonNext={{
          title: 'Next',
          action: submitStep,
          disable: !validation,
        }}
        showNextButton={docuSigned}
      />
    </Grid>
  )
}

export default Step3Homeowner
