import { Grid, Typography } from '@material-ui/core'
import { getApprovalValues, getEstimate } from 'ducks/selectors'
import { useDispatch, useSelector } from 'react-redux'
import useStyles from './styles'
import { FC, useEffect, useState } from 'react'
import { useStepperContext } from 'hooks/useStepperContext'
import { UploadDocuments } from 'components/UI'
import { filesActions } from 'ducks/actions'
import {
  FILE_TYPE,
  ParseFilesUrl,
  applicationMimeTypes,
  isEmpty,
} from 'helpers/index'
import env from '@beam-australia/react-env'
import { PunchlistFile } from 'ducks/types'

export interface ListingAgreementProps {
  listingAgreementDocument: any
  setListingAgreementDocument: (listingAgreementDocument: any) => void
}

const ListingAgreement: FC<ListingAgreementProps> = ({
  setListingAgreementDocument,
  listingAgreementDocument,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [uploading, setUploading] = useState(false)
  const { agentAsHomeownerRepresentative } = useSelector(getApprovalValues())

  const { showWarnings } = useStepperContext()

  const FILES_URL = env('FILES_URL') ?? ''

  const handleUploadFileChange = (files: any, callback: () => void) => {
    files && setListingAgreementDocument(files)
    setUploading(true)
    if (files) {
      dispatch(
        filesActions.uploadFiles(files as any, (uploadSucc, fileNameList) => {
          if (uploadSucc && fileNameList) {
            const newFiles = fileNameList.map((fileName, index) => {
              if (!isEmpty(fileName)) {
                return {
                  name: fileName,
                  fileUrl: ParseFilesUrl({
                    baseUrl: FILES_URL,
                    avatar: fileName ?? '',
                  }),
                  description: `Estimate Inspection - ${index + 1}`,
                  fileType: FILE_TYPE.ADDENDUM,
                }
              }
            })
            files && setListingAgreementDocument(newFiles)
            setUploading(false)
            // saveNewValue('documents', newFiles)
          }
          if (callback) callback()
        })
      )
    } else {
      if (callback) callback()
    }
    setUploading(false)
  }

  return (
    <>
      <Grid item>
        <Typography className={classes.title}>📑 please upload</Typography>
        <Typography variant="body1" className={classes.latoPrimaryText}>
          {agentAsHomeownerRepresentative === 'myself'
            ? 'Please upload a signed statement indicating that you are/will be representing yourself in a real estate transaction.'
            : 'Listing Agreement'}
        </Typography>
        <UploadDocuments
          files={listingAgreementDocument}
          error={showWarnings && !listingAgreementDocument.length}
          filesLimit={1}
          onChange={
            !uploading
              ? (files, callback) =>
                  handleUploadFileChange(files as any, callback)
              : () => {}
          }
          className={classes.dropzone}
          handleDelete={() => setListingAgreementDocument([])}
          allowedFiles={applicationMimeTypes}
          sx={{ minHeight: '180px !important' }}
        />
      </Grid>
    </>
  )
}

export default ListingAgreement
