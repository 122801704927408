import { Box, CircularProgress, Grid } from '@material-ui/core'
import { FC, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import {
  getApprovalValues,
  getContractType,
  getEstimate,
  getItemsInCart,
  getUserIsHomeowner,
  getUserPrimaryEmail,
} from 'ducks/selectors'
import { useStepperContext } from 'hooks/useStepperContext'
import Lottie from 'lottie-react'
import checkData from './check.json'
import { estimateActions } from 'ducks/actions'
import ItemsDetail from '../ItemsDetail'
import { formatDateToUnixTimestamp } from 'helpers/index'

interface TOSDocumentSignProps {
  title: string
  docuSigned: boolean
  docuSignUrl: string
  onSuccess?: () => void
  attributeToPatch?: 'contractSignedOn' | 'agentAcknowledgementSignedOn'
}

const TOSDocumentSign: FC<TOSDocumentSignProps> = ({
  title,
  docuSignUrl,
  onSuccess,
  docuSigned,
  attributeToPatch = 'contractSignedOn',
}) => {
  const [iframeLoading, setIframeLoading] = useState(false)
  const estimateItems = useSelector(getItemsInCart())
  const estimate = useSelector(getEstimate())
  const userEmail = useSelector(getUserPrimaryEmail)
  const { agentIsHomeowner } = useSelector(getApprovalValues())
  const isHomeowner = agentIsHomeowner || getUserIsHomeowner()
  const contactIndex = estimate?.properties.contacts?.findIndex(
    (contact) => contact.email === userEmail
  )

  const { saveNewValue } = useStepperContext()

  const dispatch = useDispatch()

  useEffect(() => {
    setIframeLoading(true)

    setTimeout(() => {
      setIframeLoading(false)
    }, 5000)
  }, [])

  function handleSign(url?: string) {
    if (!url) return

    if (
      url.includes('signin_docusign=timeout') ||
      url.includes('signin_docusign=view_exit') ||
      url.includes('signin_docusign=decline') ||
      url.includes('signin_docusign=finish_later')
    ) {
      saveNewValue('docuSigned', false)
    }

    if (url.includes('signin_docusign=success')) {
      const today = new Date()

      dispatch(
        estimateActions.updateEstimateByPath(
          [
            {
              op: 'add',
              path: `/properties/contacts/${contactIndex}/${attributeToPatch}`,
              value: formatDateToUnixTimestamp(today),
            },
            {
              op: 'replace',
              path: `/properties/contractType`,
              value: getContractType(),
            },
          ],
          (succ: boolean) => {
            if (succ) {
              saveNewValue('docuSigned', true)
              onSuccess && onSuccess()
              dispatch(estimateActions.fetchEstimate(estimate?.id || ''))
            }
          }
        )
      )
    }
  }

  useEffect(() => {
    console.log({
      docuSignUrl
    })
    dispatch(
      estimateActions.updateEstimateByPath([
        {
          op: 'add',
          path: `/properties/contacts/${contactIndex}/contractUrl`,
          value: docuSignUrl,
        },
      ])
    )
  }, [docuSignUrl])

  return (
    <Grid container direction="column" style={{ gap: '16px' }}>
      {!isHomeowner && (
        <ItemsDetail
          buttonText="Download"
          description={
            estimateItems.length > 1
              ? `${estimateItems.length} items on this purchase`
              : `${estimateItems.length} item on this purchase`
          }
          subTitle="appendix"
          title={title}
        />
      )}
      {iframeLoading && !docuSigned && (
        <Box>
          <CircularProgress size={30} />
        </Box>
      )}
      {docuSigned ? (
        <Box
          style={{
            top: isMobile ? '40%' : 'inherit',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Lottie
            animationData={checkData}
            loop={true}
            height={'200px'}
            width={'200px'}
          />
        </Box>
      ) : (
        <iframe
          style={{ border: 'none' }}
          onLoad={(e) => {
            e.preventDefault()
            handleSign(e.currentTarget.contentWindow?.window?.location?.href)
          }}
          onError={(e) => {
            console.log({ e })
          }}
          src={`${docuSignUrl}&noDownloadPrint=true&noNav=true&noTitleBar=true&noToolbar=true`}
          // src={`${docuSignUrl}&noDownloadPrint=true&noChrome=true&noNav=true&noReload=true&noResize=true&noTitleBar=true&noToolbar=true&noView`}
          title="DocuSign"
          width={isMobile ? window.screen.availWidth - 32 : '100%'}
          height={
            isMobile
              ? window.screen.availHeight - window.screen.availHeight / 3
              : 1000
          }
        />
      )}
    </Grid>
  )
}

export default TOSDocumentSign
