import { FC, useEffect } from 'react'
import { Grid } from '../../../../../../../UI'
import useStyles from '../../../styles'
import {
  getApprovalStep,
  getApprovalValues,
  getContractType,
  getEstimate,
  getEstimateItems,
  getUser,
  getUserId,
  getUserPrimaryEmail,
} from '../../../../../../../../ducks/selectors'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { useStepperContext } from '../../../../../../../../hooks/useStepperContext'
import TOSDocumentSign from '../../../components/TOSDocumentSign'
import { useDispatch, useSelector } from 'react-redux'
import env from '@beam-australia/react-env'
import BottomButtons from '../../../components/BottomButtons'
import { configActions, estimateActions } from 'ducks/actions'
import { EstimateType } from 'ducks/estimate/types'
import {
  APPROVAL_TYPE,
  companyRoles,
  CONTACT_ROLE,
  history,
  isEmpty,
} from 'helpers/index'

const DOCUSIGN_APPROVAL_LISTING_ACKNOWLEDGEMENT =
  env('DOCUSIGN_APPROVAL_LISTING_ACKNOWLEDGEMENT') ?? ''

const Step4Agent: FC = () => {
  const classes = useStyles()
  const { setShowWarnings, setCanGoForward } = useStepperContext()
  const dispatch = useDispatch()
  const approvalStep = useSelector(getApprovalStep)

  const estimate = useSelector(getEstimate())
  const userId = useSelector(getUserId)
  const user = useSelector(getUser)
  const userEmail = useSelector(getUserPrimaryEmail)
  const push = history.usePushForward()

  const { homeowner, agentAsHomeownerRepresentative } = useSelector(
    getApprovalValues()
  )

  const agentSigned = estimate?.properties.contacts?.find(
    (contact) =>
      contact?.agentAcknowledgementSignedOn?.toString()
  )

  const agentIsAlsoHomeowner = estimate?.properties.contacts?.find(
    (contact) =>
      contact.email === userEmail &&
      contact.role === CONTACT_ROLE.AGENT_HOMEOWNER
  )

  const myContactFullName = agentIsAlsoHomeowner
    ? agentIsAlsoHomeowner.fullName
    : `${user?.firstName} ${user?.lastName}`

  const homeownerFullName = agentIsAlsoHomeowner
    ? agentIsAlsoHomeowner.fullName
    : homeowner?.fullName || `${homeowner?.firstName} ${homeowner?.lastName}`

  const docuSigned =
    (!isEmpty(agentSigned) &&
      estimate?.properties?.contractType === getContractType()) ||
    false

  const validation = !!docuSigned

  const onSubmit = () => {
    dispatch(
      configActions.setConfigValue({ type: 'overlayLoading', value: true })
    )

    if (agentAsHomeownerRepresentative === 'myself') {
      dispatch(
        estimateActions.updateEstimateByPath(
          [
            {
              op: 'replace',
              path: '/properties/approvalStep',
              value: approvalStep + 1,
            },
          ],
          (succ) => {
            dispatch(
              configActions.setConfigValue({
                type: 'overlayLoading',
                value: false,
              })
            )
          }
        )
      )
    } else {
      const contacts = estimate?.properties?.contacts?.map((contact) => {
        if (contact.email === userEmail) {
          return {
            ...contact,
            approvalType: APPROVAL_TYPE.APPROVED,
          }
        }
        return contact
      })

      const payload: Partial<EstimateType> = {
        id: estimate?.id,
        properties: {
          address: estimate?.properties?.address,
          payAtClose: estimate?.properties?.payAtClose,
          accessDetails: estimate?.properties?.accessDetails,
          preferredCompletedOn: estimate?.properties?.preferredCompletedOn,
          closingDate: estimate?.properties?.closingDate,
          contacts: contacts,
          userId: userId,
          clientNotes: estimate?.properties?.clientNotes,
          totalValue: estimate?.properties?.totalValue ?? 0,
          approvalStep: 0,
        },
      }
      dispatch(
        estimateActions.approval(payload, (success: boolean) => {
          dispatch(
            configActions.setConfigValue({
              type: 'overlayLoading',
              value: false,
            })
          )
          if (success) {
            dispatch(
              configActions.setConfigValue({
                type: 'overlayLoading',
                value: false,
              })
            )
            push(`success`)
          }
        })
      )
    }
  }

  useEffect(() => {
    setShowWarnings(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setCanGoForward(!!validation)
  }, [validation])

  return (
    <Grid className={classes.container} spacing={2}>
      <TOSDocumentSign
        onSuccess={onSubmit}
        title="📋 PLEASE REVIEW AND SIGN THE AGENT ACKNOWLEDGMENT"
        docuSignUrl={`${DOCUSIGN_APPROVAL_LISTING_ACKNOWLEDGEMENT}&Signer_UserName=${myContactFullName}&Signer_Email=${encodeURIComponent(userEmail)}&AgentName=${myContactFullName}&FullAddress=${estimate?.properties.fullAddress}&AddressLine1=${estimate?.properties?.address?.line_1}&AddressLine2=${estimate?.properties.address?.line_2? estimate?.properties.address?.line_2: `${estimate?.properties.address?.city}, ${estimate?.properties.address?.state} ${estimate?.properties.address?.zipCode}`}${estimate?.properties.address?.line_2? `&AddressLine3=${estimate?.properties.address?.city}, ${estimate?.properties.address?.state} ${estimate?.properties.address?.zipCode}`: ''}&UserName=${homeownerFullName}`}
        docuSigned={docuSigned}
        attributeToPatch={'agentAcknowledgementSignedOn'}
      />
      <BottomButtons
        buttonBack={{
          title: 'Back',
          action: () => {
            dispatch(
              configActions.setConfigValue({
                type: 'overlayLoading',
                value: true,
              })
            )
            dispatch(
              estimateActions.updateEstimateByPath(
                [
                  {
                    op: 'replace',
                    path: '/properties/approvalStep',
                    value: approvalStep - 1,
                  },
                ],
                (succ) =>
                  dispatch(
                    configActions.setConfigValue({
                      type: 'overlayLoading',
                      value: false,
                    })
                  )
              )
            )
          },
        }}
        buttonNext={{
          title: 'Sign Agent Acknowledgment',
          action: onSubmit,
          disable: !validation,
        }}
        showNextButton={validation}
      />
    </Grid>
  )
}

export default Step4Agent
